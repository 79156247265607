<template>
  <div class=" ">
    <p v-if=" !needInformation ">
      <strong v-text="$t('dashboard.payments.availableBalance')"></strong>
      <br>
      <span class="f-24 text-right" v-text=" totalSummary "> </span>
      <br>
      <small class="text-success" v-text="$t('dashboard.payments.available')"> </small>
    </p>
    <div v-if=" !needInformation ">
      <div v-if=" balancesList.length > 0 ">
        <div class="card bg-light mb-2 ">
          <div class="card-body">
            <div v-if="Object.keys( balancesSummary.available ).length ">
              <div class="d-flex" v-for=" (currency,index) in Object.keys( balancesSummary.available ) " :key="index">
                <p class="m-0" v-text="currency"> </p>
                <div class="linedoted w-100 mt-auto"></div>
                <p class="m-0" v-text="fnFormatPrice( balancesSummary.available[currency], '' )">
                </p>
              </div>
            </div>
            <div v-else>
              <div class="d-flex justify-content-center">
                <p class="m-0 text-muted text-center py-2" v-text="$t('dashboard.payments.noMoneyMessage')"> </p>
              </div>
            </div>
          </div>
        </div>
        <p class="m-0 text-muted ">
          <small v-text=" $t('dashboard.payments.currencyMessage') "></small>
        </p>
        <p class="text-right">
          <button class="btn btn-success rounded-pill" @click=" fnShowPaymentModal()" :disabled="balancesList.length == 0"
            v-text="$t('dashboard.payments.withdrawFunds')"></button>
        </p>
      </div>
    </div>
    <div v-else>
      <p >
        <em class="fa fa-exclamation-triangle mr-1 text-warning"></em>
        <span v-text="$t('dashboard.payments.aditionalInformation')" ></span> 
        </p>
      <ul>
        <li v-if="methodList.length == 0">
          <p>
            <span v-text="$t('dashboard.payments.addAccountMessage')">
            </span>
            <button class="btn btn-link btn-xs p-0 ml-1" v-text="$t('general.button.addNow')"
              @click="changeModal({paymentMethod:true}) ;changeMethod(true)"> </button>
          </p>
        </li>
        <li v-if="needBilling && invociesList.length == 0">
          <p>
            <span v-text="$t('dashboard.payments.addInvoiceInformation')"></span>
            <button class="btn btn-link btn-xs p-0 ml-1 " v-text="$t('general.button.addNow')"
              @click="changeModal({ invoice: true })"> </button>
          </p>
        </li>
      </ul>
    </div>

    <b-modal :visible="modals.createPayment" :title="$t('dashboard.payments.withdrawFunds')" content-class="card form-rounded-inputs card-ecart "
      centered header-class="card-header" @hidden="fnResetPayment(); resetNewPayment()" cancel-variant="danger"
      :size=" (paymentStep == 0) ? 'lg':'md' " hide-header-close>

      <!-- Invoice Data -->
      <div class="  animated fadeIn " v-show=" paymentStep == 0">
        <LanguageLoader routeLanguage="./translations/payments/payments-taxes_">
          <div class="animated fadeIn">
            <hr>
            <p>
              Completa la siguiente informacion para poder procesar tus pagos.
            </p>
            <PaymentInvoiceForm ref="newInvoice" />
          </div>
        </LanguageLoader>
      </div>

      <div v-show=" paymentStep == 1">
        <p>
          <strong v-if="customPayment" v-text="$t('dashboard.payments.availableCurrencies')"></strong>
          <strong v-else v-text="$t('dashboard.payments.availableBalance')"></strong>
        </p>
        <div v-if="Object.keys(balancesSummary.available).length == 0 && (balancesList.length > 0)">
          <p v-text="$t('dashboard.payments.noAvailableBalances')"></p>
        </div>
        <div v-else>
          <div v-if="customPayment">

            <v-observer tag="form" @submit.prevent="fnValidateNewPayment()" ref="newCustomPayment">
              <div>
                <div class="">
                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.balance') ">
                    <div class="row">
                      <div class="col-12 col-md-6 mb-2"
                        v-for=" (currency,index) in Object.keys(balancesSummary.available) " :key="index">
                        <div class="row">
                          <div class="col-12">
                            <input type="radio" hidden @change="fnResetPayment(); fnSelectAllBalances()"
                              :value="currency" :id="currency + '-radio'" v-model="newPayment.currency">
                            <label :for=" currency + '-radio' "
                              :class=" (newPayment.currency == currency) ? 'border-success bg-success':'text-success' "
                              class="w-100 cursor-pointer card mb-2 ">
                              <div class="card-body p-2">
                                <div class="row align-items-center">
                                  <div class="col-auto">
                                    <em class="flag-icon fa-2x rounded shadow" :class="fnGetFlagByCurrency(currency)"></em>
                                  </div>
                                  <div class="col">
                                    <p class="m-0">
                                      <strong
                                        v-text="fnFormatPrice(balancesSummary.available[currency] , currency) "></strong>
                                    </p>
                                  </div>
                                </div>
                              </div>

                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="bg-danger p-1 mt-2" v-show="errors.length > 0" v-text=" errors[0] ">
                    </p>
                  </v-validation>
                </div>

                <div v-show=" newPayment.currency ">
                  <hr class="mt-0">

                  <div v-show="!getBalancesFilter.length > 0">
                    <p>
                      <em class="fa fa-info-circle text-warning mr-1"></em>
                      <span v-text="$t('dashboard.payments.noBalances')"></span>
                    </p>
                  </div>
                  <div class="mb-3" v-show="getBalancesFilter.length > 0">
                    <v-validation rules="required" v-slot="{ errors }" :name=" $t('tables.selectBalances') ">
                      <table class="table table-striped table-hover table-sm" :class=" { 'is-invalid ':errors.length > 0 } ">
                        <caption>Loading...</caption>
                        <thead>
                          <tr :class=" { 'bg-danger ':errors.length > 0 } ">
                            <th>
                              <input type="checkbox" id="selectAllBalances" :checked="selectAllBalances"
                                @click=" fnSelectAllBalances() ">
                            </th>
                            <th class="col" @click=" fnSelectAllBalances() ">
                              <p class="m-0 cursor-pointer">
                                <span v-text=" $t('tables.selectBalances') "></span>
                              </p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="" v-for=" (balance,index) in getBalancesFilter  " :key=" index ">
                            <td>
                              <input type="checkbox" v-model="newPayment.balances_id" :value="balance.id"
                                :class=" { 'is-invalid ':errors.length > 0 } " :id="balance.id">
                            </td>
                            <td>
                              <label :for=" balance.id " class="m-0 w-100">
                                <p class="m-0">
                                  <span v-text=" $t('tables.amount') + ': ' "></span>
                                  <span class=" " v-text=" fnFormatPrice( balance.amount, balance.currency ) "></span>
                                </p>
                                <p class="m-0">
                                  <span v-text=" $t('tables.order') + ': ' + balance.number "></span>
                                </p>
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class=" invalid-feedback " v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </v-validation>
                  </div>

                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.account') ">
                    <div class="form-group">
                      <label for="" v-text="$t('general.form.account')"></label>
                      <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                        <select class="custom-select " @change=" fnCheckBank "
                          :class=" { 'is-invalid ':errors.length > 0 } " v-model="newPayment.method">
                          <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                          <option :value="method.id" v-text="method.name ? method.name : method.number "
                            v-for=" method in getMethodsClabe " :key="method.id">
                          </option>
                          <option :value="method.id" v-text="method.name ? method.name : method.number "
                            v-for=" method in getMethodsDebit " :key="method.id">
                          </option>
                          <option :value="method.id" v-text="method.name ? method.name : method.email "
                            v-for=" method in getMethodsPaypal " :key="method.id"></option>
                        </select>
                        <div class="input-group-append">
                          <label class="input-group-text">
                            <em class=" fa fa-money-check-alt"></em>
                          </label>
                        </div>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </v-validation>
                  <!-- Payment invoice data -->
                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.invoice') "
                    v-if="needBilling ">
                    <div class="form-group">
                      <label for="" v-text="$t('general.form.invoice')"></label>
                      <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                        <select class="custom-select " @change=" fnCheckBank "
                          :class=" { 'is-invalid ':errors.length > 0 } " v-model="newPayment.invoice">
                          <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                          <option :value="invoice.id" v-text="invoice.key ? invoice.key : invoice.id "
                            v-for=" invoice in invociesList " :key="invoice.id">
                          </option>
                        </select>
                        <div class="input-group-append">
                          <label class="input-group-text">
                            <em class=" fa fa-file-invoice"></em>
                          </label>
                        </div>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </v-validation>

                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('dashboard.payments.bank') "
                    v-if="showBanksSelect">
                    <div class="form-group">
                      <label for="" v-text="$t('dashboard.payments.bank')"></label>
                      <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                        <select class="custom-select " :class=" { 'is-invalid ':errors.length > 0 } "
                          v-model="newPayment.bank">
                          <option :value="null" v-text=" $t('general.form.select') ">
                          </option>
                          <option :value="bank.clave" v-for="  ( bank, index ) in banksList  " :key="index"
                            v-text="bank.name">
                          </option>
                        </select>
                        <div class="input-group-append">
                          <label class="input-group-text">
                            <em class="fa fa-university"></em>
                          </label>
                        </div>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </v-validation>

                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.cardholderName') "
                    v-if="showCardName">
                    <div class="form-group">
                      <label for="" class="w-100 " v-text=" $t('general.form.cardholderName') "> </label>
                      <div class="input-group ">
                        <input type="text" class="form-control" v-model="newPayment.business_name"
                          :placeholder="$t('placeholders.cardholderName')"
                          :class=" { 'is-invalid':errors.length > 0 } ">
                        <div class="input-group-append">
                          <span class="input-group-text rounded-right">
                            <strong>
                              <em class="fa fa-user"></em>
                            </strong>
                          </span>
                        </div>
                        <div class="invalid-feedback" v-if="errors.length > 0">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </div>
                  </v-validation>
                </div>
              </div>
            </v-observer>
            <p class="text-right mb-0">
              <button class="btn btn-link btn-sm" @click="setCustomPayment(false)"
                v-text="$t('dashboard.payments.quickPayment')"></button>
            </p>
          </div>
          <div v-else>
            <v-observer tag="form" @submit.prevent="fnValidateNewPayment()" ref="newPayment">
              <div>
                <div class="">
                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.balance') ">
                    <div class="row">
                      <div class="col-12 col-md-6 mb-2"
                        v-for=" (currency,index) in Object.keys(balancesSummary.available) " :key="index">
                        <div class="row">
                          <div class="col-12">
                            <input type="radio" hidden @change=" fnResetPayment " :value="currency"
                              :id="currency + '-radio'" v-model="newPayment.currency">
                            <label :for=" currency + '-radio' "
                              :class=" (newPayment.currency == currency) ? 'border-success bg-success':'text-success' "
                              class="card mb-2 w-100 cursor-pointer">
                              <div class="card-body p-2">
                                <div class="row align-items-center">
                                  <div class="col-auto">
                                    <em class="flag-icon fa-2x rounded shadow" :class="fnGetFlagByCurrency(currency)"></em>
                                  </div>
                                  <div class="col">
                                    <p class="m-0">
                                      <strong
                                        v-text="fnFormatPrice(balancesSummary.available[currency] , currency) "></strong>
                                    </p>
                                  </div>
                                </div>
                              </div>

                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="bg-danger p-1 mt-2" v-show="errors.length > 0" v-text=" errors[0] ">
                    </p>
                  </v-validation>
                </div>

                <div v-show=" newPayment.currency ">
                  <hr class="mt-0">

                  <div v-if='paypalWarning' class='h-100'>
                    <div class='col-12'>
                      <b-alert variant='danger' show>
                        <p>
                          <strong>PayPal </strong> <span v-text="$t('dashboard.payments.paypalWarning')"></span>
                        </p>
                      </b-alert>
                    </div>
                  </div>

                  <hr v-if='paypalWarning' class="mt-0" />

                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.account') ">
                    <div class="form-group">
                      <label for="" v-text="$t('general.form.account')"></label>
                      <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                        <select class="custom-select " @change=" fnCheckBank "
                          :class=" { 'is-invalid ':errors.length > 0 } " v-model="newPayment.method">
                          <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                          <option :value="method.id" v-text="method.name ? method.name : method.number "
                            v-for=" method in getMethodsClabe " :key="method.id">
                          </option>
                          <option :value="method.id" v-text="method.name ? method.name : method.number "
                            v-for=" method in getMethodsDebit " :key="method.id">
                          </option>
                          <option :value="method.id" v-text="method.name ? method.name : method.email "
                            v-for=" method in getMethodsPaypal " :key="method.id"></option>
                        </select>
                        <div class="input-group-append">
                          <label class="input-group-text">
                            <em class=" fa fa-money-check-alt"></em>
                          </label>
                        </div>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </v-validation>

                  <!-- Payment invoice data -->
                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.invoice') "
                    v-if="needBilling ">
                    <div class="form-group">
                      <label for="" v-text="$t('general.form.invoice')"></label>
                      <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                        <select class="custom-select " @change=" fnCheckBank "
                          :class=" { 'is-invalid ':errors.length > 0 } " v-model="newPayment.invoice">
                          <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                          <option :value="invoice.id" v-text="invoice.key ? invoice.key : invoice.id "
                            v-for=" invoice in invociesList " :key="invoice.id">
                          </option>
                        </select>
                        <div class="input-group-append">
                          <label class="input-group-text">
                            <em class=" fa fa-file-invoice"></em>
                          </label>
                        </div>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </v-validation>

                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('dashboard.payments.bank') "
                    v-if="showBanksSelect">
                    <div class="form-group">
                      <label for="" v-text="$t('dashboard.payments.bank')"></label>
                      <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                        <select class="custom-select " :class=" { 'is-invalid ':errors.length > 0 } "
                          v-model="newPayment.bank">
                          <option :value="null" v-text=" $t('general.form.select') ">
                          </option>
                          <option :value="bank.clave" v-for="  ( bank, index ) in banksList  " :key="index"
                            v-text="bank.name">
                          </option>
                        </select>
                        <div class="input-group-append">
                          <label class="input-group-text">
                            <em class="fa fa-university"></em>
                          </label>
                        </div>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </v-validation>
                  <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.cardholderName') "
                    v-if="showCardName">
                    <div class="form-group">
                      <label for="" class="w-100 " v-text=" $t('general.form.cardholderName') "> </label>
                      <div class="input-group ">
                        <input type="text" class="form-control" v-model="newPayment.business_name"
                          :placeholder="$t('placeholders.cardholderName')"
                          :class=" { 'is-invalid':errors.length > 0 } ">
                        <div class="input-group-append">
                          <span class="input-group-text rounded-right">
                            <strong>
                              <em class="fa fa-user"></em>
                            </strong>
                          </span>
                        </div>
                        <div class="invalid-feedback" v-if="errors.length > 0">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </div>
                  </v-validation>

                </div>

              </div>
            </v-observer>
            <p class="text-right mb-0">
              <button class="btn btn-link btn-sm" @click="setCustomPayment(true), fnSelectAllBalances() "
                v-text="$t('dashboard.payments.customPayment')"></button>
            </p>
          </div>
        </div>
      </div>

      <div v-show=" paymentStep == 2">
        <p class="text-center mb-4">
          <img src="@/assets/images/logo-ecart.svg" class="img-contain-70 rounded-circle border-0 shadow p-1" alt="">
        </p>
        <h5 class="text-center mb-5"> <strong v-text="$t('dashboard.payments.transferReview')"></strong> </h5>
        <div class="row align-items-center">
          <div class="col-auto text-center">
            <img src="@/assets/images/logo-ecart.svg" class="img-contain-50 rounded-circle border bg-white p-1 " alt="">
          </div>
          <div class="col">
            <p class="m-0">
              <strong v-text="$t('dashboard.payments.ecartBalance')"></strong>
            </p>
            <p class="m-0">
              <strong class="text-success" v-text=" fnFormatPrice(newPayment.total , newPayment.currency) "></strong>
            </p>

          </div>
        </div>
        <hr>

        <div class="animated fadeIn">
          <div class="card">
            <div class="card-header">
              <p class="m-0" v-if="tempAccount.method">
                <strong v-text="$t('dashboard.payments.accountType') + ': ' "></strong>
                <span class="text-underline" v-text=" $t('dashboard.payments.methods.' + tempAccount.method ) "></span>
              </p>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <p v-if="tempAccount.name" class="mb-0">
                    <strong v-text="$t('dashboard.payments.accountAlias') + ': ' "></strong>
                    <span v-text="tempAccount.name"></span>
                  </p>
                  <div v-if=" tempAccount.method != 'paypal' ">
                    <p class="m-0">
                      <strong v-text=" $t('general.form.number') + ': ' ">
                      </strong>
                      <span v-text=" tempAccount.number ?  fnAccountNumber(tempAccount.number) : '' "></span>
                    </p>
                    <p class="m-0" v-if="fnGetBank(newPayment.bank)">
                      <strong v-text=" $t('general.form.bank') + ': ' "></strong>
                      <span v-text="fnGetBank(newPayment.bank)"></span>
                    </p>
                    <div v-else>
                      <p class="m-0" v-if="fnGetBank(tempAccount.bank)">
                        <strong v-text=" $t('general.form.bank') + ': ' "></strong>
                        <span v-text="fnGetBank(tempAccount.bank)"></span>
                      </p>
                    </div>
                  </div>
                  <div v-else>
                    <p class="text-truncate"> <span v-text="$t('general.form.paypalEmail') + ': ' "></span> <span
                        v-text="tempAccount.email"></span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer" v-if="invoiceInformation">
              <div class="row">
                <div class="col-12">
                  <p>
                    <strong v-text=" 'RFC: ' "></strong>
                    <span v-text="invoiceInformation.key"></span>
                  </p>
                </div>
                <div class="col-12" v-if="invoiceInformation.business_name">
                  <p>
                    <strong v-text=" $t('general.form.businessName') + ': ' "></strong>
                    <span v-text="invoiceInformation.business_name"></span>
                  </p>
                </div>
              </div>

            </div>
          </div>

          <div class="card bg-light">
            <div class="card-body">
              <div class="row justify-content-between">
                <div class="col-6">
                  <p v-text="$t('dashboard.payments.transferAmount')"></p>
                </div>
                <div class="col-auto">
                  <p v-text=" fnFormatPrice(newPayment.total , newPayment.currency) "></p>
                </div>
              </div>
              <div class="row justify-content-between " v-if="exchangeCurrency != newPayment.currency">
                <div class="col-6">
                  <p v-text="$t('dashboard.payments.exchangeRate')"></p>
                </div>
                <div class="col-auto">
                  <p
                    v-text=" `1 ${newPayment.currency} = ` +  (( getExchangeCurrency ?  getExchangeCurrency.toFixed(4) : '' ) + exchangeCurrency )  ">
                  </p>
                </div>
              </div>

              <div class="row justify-content-between ">
                <div class="col-6">
                  <p v-text="$t('dashboard.payments.withdrawalFee')"></p>
                </div>
                <div class="col-auto">
                  <p> $0.00 </p>
                </div>
              </div>

              <hr>
              <div class="row justify-content-between ">
                <div class="col-6">
                  <p> <strong v-text="$t('dashboard.payments.youWillGet')"></strong> </p>
                </div>
                <div class="col-auto text-success ">
                  <p>
                    <strong v-text=" fnFormatPrice((newPayment.total * getExchangeCurrency) , exchangeCurrency) "
                      v-if=" exchangeCurrency != newPayment.currency "></strong>
                    <strong v-text=" fnFormatPrice(newPayment.total , newPayment.currency) " v-else></strong>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer>
        <div class="d-flex w-100">

          <b-button variant="primary" class=" " v-if=" paymentStep == 2 " @click=" setPaymentStep(1) "
            v-text=" $t('general.button.back') ">
          </b-button>

          <button @click=" fnResetPayment(); resetNewPayment() " class="btn ml-auto"
            v-text=" $t('general.button.close') "></button>

          <button class=" btn btn-success " :disabled="loading.sendingNewPayment" @click="fnValidateStep()">
            <span v-text="( paymentStep == 2 )?  $t('general.button.create') : $t('general.button.next') " v-if=" !loading.sendingNewPayment "></span>
            <em class="fa fa-spinner fa-spin " v-else></em>
          </button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";
  import LanguageLoader from '@/components/TranslationsLoader.vue';

  import PaymentInvoiceForm from './payments-invoice-form';
  export default {
    components: {
      LanguageLoader,
      PaymentInvoiceForm,
    },
    data() {
      return {
        paypalWarning: false,
        tempAccount: {},
      };
    },
    computed: {
      invoiceInformation() {
        if (this.newPayment.invoice) {
          return this.invociesList.find(invoice => invoice.id == this.newPayment.invoice);
        } else {
          return false;
        }
      },
      totalSummary() {
        let tempKeys = Object.keys(this.balancesSummary.summary);
        if (tempKeys.length > 0) {
          return this.fnFormatPrice(this.balancesSummary.summary[tempKeys[0]], tempKeys[0])
        } else {
          return this.fnFormatPrice(0.00, '');
        }
      },
      selectAllBalances() {
        return this.newPayment.balances_id.length == this.getBalancesFilter.length;
      },
      ...mapState('session', ['user']),
      ...mapState('payments', ['newPayment', 'banksList', 'balancesSummary', 'balancesList', 'loading', 'methodList',
        'modals', 'paymentStep', 'showBanksSelect', 'customPayment', 'exchangeCurrency', 'showCardName',
        'newMethod', 'invociesList', 'cfdiList'
      ]),
      ...mapGetters('payments', ['getMethodsPaypal', 'getMethodsDebit', 'getMethodsClabe', 'getBalancesFilter',
        'getTotalBalances', 'getExchangeCurrency'
      ]),
      ...mapGetters('session', ['needBilling']),

      needInformation() {
        return (this.needBilling && (this.invociesList.length == 0)) || (this.methodList.length == 0) ? true : false;
      }
    },
    watch: {
      needInformation() {
        if (!this.needInformation) {
          this.fnApiGetBalances();
          this.fnApiGetBalancesSummary();
        }
      }
    },
    methods: {
      ...mapMutations('payments', ['resetNewPayment', 'changeModal', 'setPaymentStep', 'setBanksSelect',
        'setCardname', 'setCustomPayment', 'changeView', 'changeMethod'
      ]),
      ...mapActions('payments', ['fnApiCreatePayment', 'fnCreateInvoice', 'fnApiGetBalancesSummary',
        'fnApiGetExchange', 'fnApiGeInvoices', 'fnApiGetCfdiList', 'fnApiGetBalances'
      ]),

      addNewMethod(type) {
        this.newMethod.method = type;
        this.changeModal({
          paymentMethod: true
        });
      },

      fnValidateStep() {
        switch (this.paymentStep) {
          case 0:
            this.$refs['newInvoice'].fnValidateInvoiceData()
            break;
          case 1:
            this.fnValidateNewPayment();
            break;
          case 2:
            this.fnApiCreatePayment();
            break;
        }
      },
      async fnValidateInvoiceData() {
        if (await this.$refs['formInvoiceData'].validate()) {
          this.fnCreateInvoice();
        }
      },
      async newCustomPaymentProcess() {
        if (await this.$refs['newCustomPayment'].validate()) {
          this.getBalancesFilter.map(balance => {
            if (this.newPayment.balances_id.includes(balance.id)) {
              this.newPayment.total = this.newPayment.total + balance.amount;
            }
          });
          this.setPaymentStep(2);
        }
      },
      async newPaymentProcess() {
        if (await this.$refs['newPayment'].validate()) {
          if (this.tempAccount.method === 'paypal' && this.user.country === 'MX') {
            this.paypalWarning = true;
          } else {
            this.paypalWarning = false;
            this.balancesList.map(balance => {
              if (balance.currency == this.newPayment.currency) {
                this.newPayment.balances_id.push(balance.id)
                this.newPayment.total = this.newPayment.total + balance.amount;
              }
            });
            this.setPaymentStep(2);
          }
        }
      },
      async fnValidateNewPayment() {
        this.newPayment.total = 0;
        if (this.customPayment) {
          this.newCustomPaymentProcess();
        } else {
          this.newPaymentProcess();
        }
      },
      fnResetPayment() {
        this.newPayment.method = null;
        this.newPayment.bank = null;
        this.newPayment.balances_id = [];
        this.tempAccount = {};
        this.paypalWarning = false;
        this.setBanksSelect(false);
        this.setCardname(false);
        this.fnApiGetExchange();
      },
      fnCheckBank() {
        if (this.newPayment.method) {
          this.newPayment.bank = null;
          this.tempAccount = this.methodList.find(item => item.id == this.newPayment.method);
          if ((!this.tempAccount.bank || typeof (this.tempAccount.bank) == 'string') && (this.tempAccount.method !=
              'paypal')) {
            this.setBanksSelect(true);
          } else {
            this.setBanksSelect(false);
          }

          if (!this.tempAccount.business_name && (this.tempAccount.method != 'paypal')) {
            this.setCardname(true);
          } else {
            this.setCardname(false);
          }

        } else {
          this.setBanksSelect(false);
          this.setCardname(false);
        }
      },
      fnSelectAllBalances() {
        if (this.newPayment.balances_id.length < this.getBalancesFilter.length) {
          this.newPayment.balances_id = [];
          for (let index in this.getBalancesFilter) {
            this.newPayment.balances_id.push(this.getBalancesFilter[index].id);
          }
        } else {
          this.newPayment.balances_id = [];
        }
      },
      fnGetBank(bankId) {
        let tempBank = this.banksList.find(bank => bank.clave == bankId);
        if (tempBank) {
          return tempBank.name;
        }
        return null;
      },
      fnShowPaymentModal() {
        this.changeModal({
          createPayment: true
        });

        this.setPaymentStep(1);
        let tempCurrencies = Object.keys(this.balancesSummary.available);
        if (tempCurrencies.length > 0) {
          this.newPayment.currency = tempCurrencies[0];
          this.fnApiGetExchange();
        }

      }
    },
    async mounted() {
      await this.fnApiGetCfdiList()
      this.fnApiGeInvoices();
    }
  }
</script>